<template>
	<div class="md-layout">
		<div
			class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<i class="material-icons">playlist_add</i>
					</div>
				</md-card-header>
				<md-card-content>
					<md-button class="md-info" @click="newPlaylist()"><i class="fas fa-plus"></i> Nova Playlist
					</md-button>
					<div
						class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
						<!-- <md-table v-model="playlists" table-header-color="green">
							<md-table-row slot="md-table-row" slot-scope="{ item }">
								<md-table-cell md-label="#" style="width:30px"><span class="material-icons">reorder</span></md-table-cell>
								<md-table-cell md-label="Nome">{{ item.name }}</md-table-cell>
								<md-table-cell md-label="Total de músicas">{{ item.qtd_musics }}</md-table-cell>
								<md-table-cell md-label="Ações">
									<div class="container-btn-edit">
										<div class="row-btn-edit-playlist">
											<el-button icon="el-icon-edit" circle @click="editPlaylist(item)">
											</el-button>
										</div>
										<div class="row-btn-edit-playlist">
											<el-button icon="el-icon-delete" circle @click="deleteMusicAndFolder(item)">
											</el-button>
										</div>
									</div>
								</md-table-cell>
                                <md-table-cell :value="item.playlist_id" style="display:none; width:-1px">{{item.playlist_id}}</md-table-cell>
							</md-table-row>
						</md-table> -->

                        <div style="display:flex; margin-top:20px;">
                            <div class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-33 md-large-size-33 md-xlarge-size-33" style="font-size:20px; color:#5db994;">Nome</div>
                            <div class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-33 md-large-size-33 md-xlarge-size-33" style="font-size:20px; color:#5db994;">Total de músicas</div>
                            <div class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-33 md-large-size-33 md-xlarge-size-33" style="font-size:20px; color:#5db994;">Ações</div>
                        </div>
                        <draggable v-model="playlists" v-bind="dragOptions"
                            class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
                            style="padding-top:20px;">
                            <transition-group type="transition" name="flip-list" @start="isDragging = true"
                                @end="isDragging = false">
                                <div v-for="(element, index) in playlists" :key="element.name" class="border-row" style="display:flex; height: 60px; align-items: center;">
                                    <span class="material-icons">reorder</span>
                                    <div class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-33 md-large-size-33 md-xlarge-size-33">{{element.name}}</div>
                                    <div class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-33 md-large-size-33 md-xlarge-size-33">{{element.qtd_musics}}</div>
                                    <div class="container-btn-edit">
                                        <div class="row-btn-edit-playlist">
                                            <el-button icon="el-icon-edit" circle @click="editPlaylist(element)"></el-button>
                                        </div>
										<div class="row-btn-edit-playlist">
											<el-button icon="el-icon-delete" circle @click="deleteMusicAndFolder(element)">
											</el-button>
										</div>
									</div>
                                </div>
                            </transition-group>
                        </draggable>
					</div>
					
					<div style="margin-top:15px;" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
						<span style="margin-left:15px;">Para reordenar as playlists basta clicar na playlist desejada e arrastá-la para cima ou para baixo. Clique no botão salvar para guardar as alterações!</span>
					</div>
                    <md-button class="md-success" style="margin-left:30px;" @click="saveOrderPlaylists()"><i class="fas fa-check"></i> Salvar</md-button>
				</md-card-content>
			</md-card>
			<md-card style="text-align:center;" v-show="playlists.length <= 0">
				<md-card-content>
					<label for="">Sem informações pra serem exibidas.</label>
				</md-card-content>
			</md-card>
		</div>

		<el-dialog :visible.sync="dialogPlaylist" width="60%">
			<div
				class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
				<el-steps :active="active" simple finish-status="success" :space="200">
					<el-step title="Selecionar Músicas" icon="fas fa-music"></el-step>
					<el-step title="Ordenar Playlist" icon="fas fa-sort-amount-down"></el-step>
					<el-step title="Validar e Finalizar" icon="fas fa-tasks"></el-step>
				</el-steps>

				<div v-show="active == 0" class="order-list">
					<b>Dê um nome para a playlist e em seguida escolha as músicas que irão fazer parte da mesma.</b>
					<div
						class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
						<el-input v-model="namePlaylist" placeholder="Nome da playlist"></el-input>
					</div> <br>
					<div class="box-playlist" style="margin-top: -10px;">
						<span><b>Músicas disponíveis para adicionar</b></span>
						<el-tree class="tree-playlist" :data="dataFiles" show-checkbox node-key="id"
							:props="defaultProps" :check-on-click-node="true" @check="handleCheckChange">
						</el-tree>
					</div>
				</div>

				<div v-show="active == 1" class="order-list">
					<b>Para ordenar as músicas, clique e arraste para cima ou para baixo.</b>
					<draggable v-model="arrMusicSelect" v-bind="dragOptions"
						class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
						style="padding-top:20px;">
						<transition-group type="transition" name="flip-list" @start="isDragging = true"
							@end="isDragging = false">
							<div v-for="(element, index) in arrMusicSelect" :key="element.label" class="border-row">
								{{element.label}}
							</div>
						</transition-group>
					</draggable>
				</div>

				<div v-show="active == 2" class="order-list">
					<b>Confira como ficou a ordenação antes de finalizar. Caso necessário volte ao passo anterior e faça
						os ajustes.</b> <br>
					<span>Nome da Playlist: {{namePlaylist}}</span>
					<ul>
						<li v-for="(element, index) in arrMusicSelect" :key="element.label">
							{{element.label}}
						</li>
					</ul>
				</div>
				<div
					class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
					<!-- <el-button style="margin-top: 12px;" @click="this.dialogPlaylist = false">Voltar</el-button> -->
					<el-button v-show="active == 1 || active == 2" style="margin-top: 12px;" @click="active = active-1">
						Voltar</el-button>
					<el-button v-show="active != 2" style="margin-top: 12px;" @click="next"
						:disabled="arrMusicSelect.length == 0 || namePlaylist ==''">Próximo passo</el-button>
					<el-button v-show="active == 2" style="margin-top: 12px;" @click="createPlaylist">Finalizar
					</el-button>
				</div>
			</div>
		</el-dialog>

		<!-- EDIT -->
		<el-dialog :visible.sync="dialogPlaylistEdit" width="60%" :close-on-click-modal="false">
			<div
				class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
				<el-steps :active="active" simple finish-status="success" :space="200">
					<el-step title="Selecionar Músicas" icon="fas fa-music"></el-step>
					<el-step title="Ordenar Playlist" icon="fas fa-sort-amount-down"></el-step>
					<el-step title="Validar e Finalizar" icon="fas fa-tasks"></el-step>
				</el-steps>

				<div v-show="active == 0" class="order-list">
					<b>Dê um nome para a playlist e em seguida escolha as músicas que irão fazer parte da mesma.</b>
					<div
						class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
						<el-input v-model="namePlaylistEdit" placeholder="Nome da playlist"></el-input>
					</div> <br>
					<div class="md-layout">
						<div class="md-layout-item md-xs-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50"
							style="margin-top:-10px;">
							<div class="box-playlist" id="boxTree">
								<span><b>Músicas disponíveis para adicionar</b></span>
								<el-tree class="tree-playlist" :data="dataFilesEdit" show-checkbox node-key="id"
									:props="defaultProps" :check-on-click-node="true" @check="handleCheckChangeEdit"
									ref="tree">
								</el-tree>
							</div>
						</div>
						<div
							class="md-layout-item md-xs-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
							<ul id="ul-playlist" class="box-playlist" style="margin-top: -10px;">
								<span><b>Playlist atual</b></span>
								<li v-for="item in musicsPlaylist" :key="item" class="li-playlist">
									{{item.label}} <el-button @click="removeMusicPlaylist(item.id)" icon="el-icon-back"
										circle size="mini" style="float:right;"></el-button>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div v-show="active == 1" class="order-list">
					<b>Para ordenar as músicas, clique e arraste para cima ou para baixo.</b>
					<draggable v-model="musicsPlaylist" v-bind="dragOptions"
						class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
						style="padding-top:20px;">
						<transition-group type="transition" name="flip-list" @start="isDragging = true"
							@end="isDragging = false">
							<div v-for="(element, index) in musicsPlaylist" :key="element.label" class="border-row">
								{{element.label}}
							</div>
						</transition-group>
					</draggable>
				</div>

				<div v-show="active == 2" class="order-list">
					<b>Confira como ficou a ordenação antes de finalizar. Caso necessário volte ao passo anterior e faça
						os ajustes.</b> <br>
					<span>Nome da Playlist: {{namePlaylistEdit}}</span>
					<ul>
						<li v-for="(element, index) in musicsPlaylist" :key="element.label">
							{{element.label}}
						</li>
					</ul>
				</div>
				<div
					class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
					<!-- <el-button style="margin-top: 12px;" @click="this.dialogPlaylistEdit = false">Voltar</el-button> -->
					<el-button v-show="active == 1 || active == 2" style="margin-top: 12px;" @click="active = active-1">
						Voltar</el-button>
					<el-button v-show="active != 2" style="margin-top: 12px;" @click="nextEdit"
						:disabled="musicsPlaylist.length == 0 || namePlaylistEdit ==''">Próximo passo</el-button>
					<el-button v-show="active == 2" style="margin-top: 12px;" @click="updatePlaylist">Atualizar
					</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		Dialog,
		Button,
		Input,
		Tabs,
		Tree,
		TabPane,
		Upload,
		TimeSelect,
		Table,
		TableColumn,
		Step,
		Steps,
		Collapse,
		CollapseItem
	} from 'element-ui'
	import models from '../../../routes/models'
	import swal from 'sweetalert2'
	import draggable from 'vuedraggable'
	import jwt_decode from "jwt-decode"

	export default {
		data: () => ({
			radio: jwt_decode(localStorage.getItem('APP_01')).radio,
			dialogPlaylist: false,
			dialogPlaylistEdit: false,
			dataFiles: [],
			dataFilesEdit: [],
			defaultProps: {},
			arrMusicSelect: [],
			namePlaylist: '',
			namePlaylistEdit: '',
			playlists: [],
			musicsPlaylist: [],
			idPlaylistUpdate: null,
			active: 0,
			row: ''
		}),
		components: {
			[Dialog.name]: Dialog,
			[Button.name]: Button,
			[Input.name]: Input,
			[Tabs.name]: Tabs,
			[TabPane.name]: TabPane,
			[Upload.name]: Upload,
			[TimeSelect.name]: TimeSelect,
			[TableColumn.name]: TableColumn,
			[Table.name]: Table,
			[Tree.name]: Tree,
			[Steps.name]: Steps,
			[Step.name]: Step,
			[Collapse.name]: Collapse,
			[CollapseItem.name]: CollapseItem,
			draggable
		},
		methods: {
			newPlaylist() {
				this.active = 0
				this.arrMusicSelect = []
				this.namePlaylist = ''
				swal({
					title: "Aguarde",
					html: "Buscando músicas e pastas...",
					onOpen: () => {

					}
				})
				this.dataFiles = []

				//Busca todos os arquivos do upload
				Promise.resolve(this.getFiles()).then((allMusicFiles) => {
						swal.close()
						if (allMusicFiles.data.length <= 0) {
							swal(
								'Atenção',
								'Nenhuma pasta ou música foram encontradas. Antes de prosseguir faça o upload!',
								'warning'
							)
						} else {
							for (let obj of allMusicFiles.data) {
								//Vefifica se tem alguma pasta
								if (obj.folder) {
									let childrens = []
									childrens.push({
										id: obj.id,
										label: `${obj.title} - ${obj.artist}`
									})
									let auxIndex = this.dataFiles.findIndex((item) => {
										return item.label === obj.folder
									});
									if (auxIndex >= 0) {
										this.dataFiles[auxIndex].children.push(childrens[0]);
									} else {
										this.dataFiles.push({
											id: this.dataFiles.length,
											label: obj.folder,
											children: childrens
										})
									}
									this.dialogPlaylist = true
								} else {
									this.dataFiles.push({
										id: obj.id,
										label: `${obj.title} - ${obj.artist}`
									})
									this.dialogPlaylist = true
								}
							}
						}
					})
					.catch((e) => {
						console.log(e)
					})
			},
			async getFiles() {
				return await models().get(`music/getUploadedMusicsPerRadio?appKey=${this.radio.key}`)
			},
			async getItensFolder(folder) {
				return await models().get(
					`music/getItensFolder?appKey=${this.radio.key}&folder=${folder}`)
			},
			handleCheckChange(data) {
				if (data.children) {
					for (let obj of data.children) {
						let index = this.arrMusicSelect.findIndex((item) => item.label === obj.label);
						if (index !== -1) {
							this.arrMusicSelect.splice(index, 1);
						} else {
							this.arrMusicSelect.push({
								id: obj.id,
								label: obj.label
							})
						}
					}
				} else {
					let index = this.arrMusicSelect.findIndex((item) => item.label === data.label);
					if (index !== -1) {
						this.arrMusicSelect.splice(index, 1);
					} else {
						this.arrMusicSelect.push({
							id: data.id,
							label: data.label
						})
					}
				}
			},
			createPlaylist() {
				this.dialogPlaylist = false
				swal.fire({
					title: "Aguarde",
					html: "Cadastrando nova playlist...",
					onOpen: () => {
						swal.showLoading()
					}
				})
				let arrayMusics = []
				const formData = {
					name: this.namePlaylist,
					appKey: this.radio.key
				}
				models().post("music/createPlaylists", formData)
					.then((playlist) => {
						swal.close()
						for (let element of this.arrMusicSelect) {
							let formMusic = {
								playListId: playlist.data[0].id,
								musicId: element.id
							}

							arrayMusics.push(new Promise((resolve, reject) => {
								models().post("music/insertMusicsPlaylist", formMusic)
									.then((resp) => {
										resolve(resp.status)
									})
									.catch((e) => {
										reject(e)
									})
							}))
						}

						let promise = Promise.all(arrayMusics)
						promise.then(() => {
							this.getPlaylists()
							swal.fire({
								title: "Pronto",
								html: "Playlist criada com sucesso!",
								type: "success",
								onOpen: () => {
									swal.hideLoading()
								}
							})
						})
					})
					.catch((e) => {
						console.log(e)
						swal(
							'Erro',
							'Não foi possível criar a playlist, por favor, tente novamente.',
							'error'
						)
					})
			},
			getPlaylists() {
				models().get(`music/getPlaylists?appKey=${this.radio.key}`)
					.then((resp) => {
						this.playlists = resp.data
					})
					.catch((e) => {
						console.log(e)
					})
			},
			editPlaylist(item) {
				this.active = 0
				this.namePlaylistEdit = item.name
				this.dataFilesEdit = []
				this.musicsPlaylist = []
				this.idPlaylistUpdate = item.playlist_id

				models().get(`music/getPlaylistsMusics?playlistId=${item.playlist_id}`)
					.then((playlist) => {
						//Prenche a playlist atual do usuário
						for (let item of playlist.data) {
							this.musicsPlaylist.push({
								id: item.id,
								label: `${item.title} - ${item.artist}`
							})
						}

						//Busca todos os arquivos do upload
						Promise.resolve(this.getFiles()).then((allMusicFiles) => {
								swal.close()
								if (allMusicFiles.data.length <= 0) {
									swal(
										'Atenção',
										'Nenhuma pasta ou música foram encontradas. Antes de prosseguir faça o upload!',
										'warning'
									)
								} else {
									for (let obj of allMusicFiles.data) {
										//Vefifica se tem alguma pasta
										if (obj.folder) {
											let childrens = []
											childrens.push({
												id: obj.id,
												label: `${obj.title} - ${obj.artist}`
											})
											let auxIndex = this.dataFilesEdit.findIndex((item) => {
												return item.label === obj.folder
											});
											if (auxIndex >= 0) {
												this.dataFilesEdit[auxIndex].children.push(childrens[0]);
											} else {
												this.dataFilesEdit.push({
													id: this.dataFilesEdit.length,
													label: obj.folder,
													children: childrens
												})
											}
											this.dialogPlaylistEdit = true
										} else {
											this.dataFilesEdit.push({
												id: obj.id,
												label: `${obj.title} - ${obj.artist}`,
												defaultProps: {
													children: 'children',
													label: 'label'
												}
											})
											this.dialogPlaylistEdit = true
										}
									}
								}
							})
							.catch((e) => {
								console.log(e)
							})
					})
			},
			handleCheckChangeEdit(data, checkProps) {
				checkProps.checkedKeys.find((item) => console.log(item))
				if (checkProps.checkedKeys.findIndex((item) => item == data.id) >= 0) {
					let index = this.musicsPlaylist.findIndex((item) => item.label === data.label);
					if (index !== -1) {
						this.musicsPlaylist.splice(index, 1);
					} else {
						if (data.children == undefined) {
							this.musicsPlaylist.push({
								id: data.id,
								label: data.label
							})
						} else {
							let self = this;
							data.children.map(function (x) {
								let index2 = self.musicsPlaylist.findIndex((item) => item.label === x.label);
								if (index2 !== -1) {
									self.musicsPlaylist.splice(index2, 1);
								}
								self.musicsPlaylist.push({
									id: data.id + '|' + x.id,
									label: x.label
								})
							})
						}
					}
				} else {
					let index = this.musicsPlaylist.findIndex((item) => item.label === data.label);
					if (index !== -1) {
						this.musicsPlaylist.splice(index, 1);
					} else {
						let self = this;
						data.children.map(function (x) {
							let index2 = self.musicsPlaylist.findIndex((item) => item.label === x.label);
							if (index2 !== -1) {
								self.musicsPlaylist.splice(index2, 1);
							}
						})
					}
				}

			},
			removeMusicPlaylist(id) {
				console.log(id)
				let index = this.musicsPlaylist.findIndex((item) => item.id === id)
				this.musicsPlaylist.splice(index, 1)
				let indexPlaylist = this.dataFilesEdit.findIndex((item) => item.id === id)
				if (indexPlaylist === -1) {
					let auxId = id.split('|');
					if (auxId.length > 1) {
						indexPlaylist = this.dataFilesEdit.findIndex((item) => auxId[0] == item.id)
						let self = this
						this.dataFilesEdit[indexPlaylist].children.map(function (item) {
							if (item.id == auxId[1]) {
								self.$refs.tree.setChecked(item, false)
							}
						})
					}
				} else {
					this.$refs.tree.setChecked(this.dataFilesEdit[indexPlaylist], false)
				}
			},
			next() {
				if (this.arrMusicSelect.length > 0 && this.namePlaylist !== "") {
					if (this.active++ > 2) this.active = 0;
				}
			},
			nextEdit() {
				if (this.namePlaylistEdit !== "") {
					if (this.active++ > 2) this.active = 0;
				}
			},
			previous() {
				if (this.active == 1) {
					this.active = 0
				} else if (this.active == 2) {
					this.active = 1
				}
			},
			updatePlaylist() {
				this.dialogPlaylistEdit = false
				let formData = {
					playlistId: this.idPlaylistUpdate,
					name: this.namePlaylistEdit
				}
				models().put("music/updatePlaylist", formData)
					.then(() => {
						this.updateMusicsPlaylist()
					})
					.catch((e) => {
						console.log(e)
					})
			},
			async updateMusicsPlaylist() {
				let aux = []
				for (let music of this.musicsPlaylist) {
					aux.push(this.idPlaylistUpdate + '-' + music.id)
				}
				let musics = aux.join('|')
				swal({
					title: "Aguarde",
					html: "Atualizando playlist...",
					onOpen: () => {
						swal.showLoading()
					}
				})

				Promise.resolve(this.deleteMusicsPlaylist())
					.then(() => {
						return models().put("music/updateMusicsPlaylist", {
								musics
							})
							.then(() => {
								swal(
									'Pronto',
									'Playlist atualizada com sucesso!',
									'success'
								)
								this.getPlaylists()
							})
					})
			},
			async deleteMusicsPlaylist() {
				let formData = {
					playlistId: this.idPlaylistUpdate
				}

				return await models().post("music/deleteMusicsPlaylist", formData)
			},
			deleteMusicAndFolder(item) {
				swal.fire({
					title: 'Atenção',
					text: "Deseja realmente apagar essa playlist?",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Confirmar',
					cancelButtonText: 'Cancelar'
				}).then((result) => {
					if (result.value) {
						models().post("music/deletePlaylistAll", {
								playlistId: item.playlist_id
							})
							.then((resp) => {
								if (resp.data) {
									this.getPlaylists()
								}
							})
							.catch((e) => {
								console.log(e)
							})
					}
				})
			},
            saveOrderPlaylists() {
                models().post(`music/updatePlaylistOrder`, { obj: this.playlists })
                    .then(() => {
						swal(
							'',
							'Playlist reordenada!',
							'success'
						)
                        this.getPlaylists()
                    })
                    .catch((error) => {
                        console.log(console.error(error))
                    })
            }

		},
		mounted() {
			this.getPlaylists()
		},
		computed: {
			dragOptions() {
				return {
					animation: 0,
					group: "description",
					disabled: false,
					ghostClass: "ghost"
				};
			}
		}
	}

</script>

<style>
	.button {
		margin-top: 35px;
	}

	.flip-list-move {
		transition: transform 0.5s;
	}

	.no-move {
		transition: transform 0s;
	}

	.ghost {
		opacity: 0.5;
		background: #c8ebfb;
	}

	.list-group {
		min-height: 20px;
	}

	.list-group-item {
		cursor: move;
	}

	.list-group-item i {
		cursor: pointer;
	}

	.border-row {
		border: 1px solid;
		padding: 6px;
		border-radius: 2px;
		border-color: #a9a9a936;
		cursor: pointer;
	}

	.el-steps.el-steps--simple {
		align-items: baseline !important;
	}

	.el-step.is-simple {
		align-items: flex-start !important;
	}

	.el-step__icon-inner.fas {
		font-size: 14px !important;
		margin-top: 3px !important;
	}

	.order-list {
		padding-top: 20px;
	}

	.order-list>span {
		padding-top: 20px;
	}

	.order-list>ul {
		list-style: decimal;
	}

	.btn-actions {
		cursor: pointer;
	}

	.box-playlist {
		/* border: 1px solid #dcdfe6; */
		border-radius: 5px;
		padding: 15px;
	}

	.li-playlist {
		padding-top: 8px;
		margin-left: 15px;
		list-style: decimal;
	}

	.tree-playlist {
		padding-top: 8px;
	}

	#boxTree {
		border-right: 1px solid #dcdfe6;
		border-radius: 1px;
	}

	.row-btn-edit-playlist {
		width: 50px !important;
	}

	td,
	tr,
	th {
		border-collapse: collapse;
		cursor: pointer;
	}

	table {
		border-collapse: collapse;
		-webkit-user-select: none;
		/* Safari */
		-ms-user-select: none;
		/* IE 10+ and Edge */
		user-select: none;
		/* Standard syntax */
	}

</style>
